import { notifStorage, SubscriptionType } from 'experimental/notifications/storage';
import { globalServerAddress } from 'routes/utils';
import { ClusterDetails, ClusterID, ClusterRole } from 'saasTypes';
import { detApi } from 'services/apiConfig';
import * as GlobalApi from 'services/global-bindings';
import * as RegionalApi from 'services/regional-bindings';
import { DetError } from 'utils/error';
import { decodePoolConfig } from 'utils/saas';
import { generateDetApi, identity } from 'utils/service';

import { ClusterScope } from './types';
export { isAuthFailure } from 'utils/service';

/*
supported operations on global management plane:
  refreshJWT(
  userInfo(

  trialRequest(
  getLicensingStatus(
  startTrial(

  listOrgs(
  createOrg(
  updateOrg(
  getOrgUsers(
  updateOrgUser(
  deleteOrgUser(
  getOrgBackendProviderData(
  getOrgBackendProviders(
  createBackendProvider(
  updateBackendProvider(
  getSupportMatrix(

  getOrgInvites(
  inviteOrgUser(
  cancelOrgInvite(

  listClusters(
  getClusterUsers(
  getUserClusters(
  getClusterOverrides(
  updateClusterUser(
  deleteClusterOverride(
  updateClusterLevelRole(

supported operations on regional management plane:
  ec2ClusterCreationPrecheck(
  byokClusterCreationPrecheck(
  listClusters(
  createCluster(
  getCluster(
  deleteCluster(
  pauseCluster(
  resumeCluster(
  updateCluster(
  getClusterActions(
*/

/* Auth Apis */
export const refreshToken = generateDetApi<
  void,
  GlobalApi.ModelRESTResponse,
  GlobalApi.ModelRESTResponse
>({
  name: 'refreshToken',
  postProcess: (response) => response,
  request: (_, options) => detApi.Global.Auth.refreshJWT(options),
});

/* Cluster Creation Precheck */
type Ec2PrecheckResponse = Omit<
  Required<RegionalApi.ModelClusterCreationPrecheckResponse>,
  'location' | 'requestId' | 'byokPrecheck'
>;

type ByokPrecheckResponse = Omit<
  Required<RegionalApi.ModelClusterCreationPrecheckResponse>,
  'location' | 'requestId' | 'ec2Precheck'
>;

export const ec2ClusterCreationPrecheck = generateDetApi<
  { orgId: string; regionId: string },
  Ec2PrecheckResponse,
  RegionalApi.ModelEc2ClusterCreationPrecheck
>({
  name: 'clusterCreationPrecheck',
  postProcess: (response) => response.ec2Precheck,
  request: async ({ orgId, regionId }, options) => {
    const response = await detApi.Regional[regionId].Cluster.clusterCreationPrecheck(
      orgId,
      options,
    );
    // have the field validated here since promises are expected to be able to fail
    return {
      ...response,
      ec2Precheck: response.ec2Precheck!,
    };
  },
});

export const byokClusterCreationPrecheck = generateDetApi<
  { orgId: string; regionId: string },
  ByokPrecheckResponse,
  RegionalApi.ModelByokClusterCreationPrecheck
>({
  name: 'clusterCreationPrecheck',
  postProcess: (response) => response.byokPrecheck,
  request: async ({ orgId, regionId }, options) => {
    const response = await detApi.Regional[regionId].Cluster.clusterCreationPrecheck(
      orgId,
      options,
    );
    // have the field validated here since promises are expected to be able to fail
    return {
      ...response,
      byokPrecheck: response.byokPrecheck!,
    };
  },
});

export const userInfo = generateDetApi<void, GlobalApi.ModelJWT, GlobalApi.ModelJWT>({
  name: 'userInfo',
  postProcess: (response) => response,
  request: (_, options) => detApi.Global.Auth.userInfo(options),
});

/* Licensing */
export const trialRequest = generateDetApi<
  GlobalApi.ModelTrialSignUpRequest,
  GlobalApi.ModelRESTResponse,
  GlobalApi.ModelRESTResponse
>({
  name: 'trialRequest',
  postProcess: (response) => response,
  request: async (signUp: GlobalApi.ModelTrialSignUpRequest, options) =>
    await detApi.Global.Licensing.trialRequest(signUp, options),
});

export const getLicensingStatus = generateDetApi<
  { userId: string },
  GlobalApi.ModelUserLicensingStatusResponse,
  GlobalApi.ModelUserLicensingStatusResponse
>({
  name: 'getLicensingStatus',
  postProcess: (response) => response,
  request: async ({ userId }, options) =>
    await detApi.Global.Licensing.getLicensingStatus(userId, options),
});

export const startTrial = generateDetApi<
  { userId: string },
  GlobalApi.ModelUserLicensingStatusResponse,
  GlobalApi.ModelUserLicensingStatusResponse
>({
  name: 'startTrial',
  postProcess: (response) => response,
  request: async ({ userId }, options) => await detApi.Global.Licensing.startTrial(userId, options),
});

/* Orgs */
export const fetchOrgs = generateDetApi<
  void,
  GlobalApi.ModelListOrgsResponse,
  GlobalApi.ModelListOrgsResponse
>({
  name: 'fetchOrgs',
  postProcess: (response) => response,
  request: (_, options) => detApi.Global.Orgs.listOrgs(options),
});

export const fetchAllOrgs = generateDetApi<
  void,
  GlobalApi.ModelListOrgsResponse,
  GlobalApi.ModelListOrgsEntry[]
>({
  name: 'fetchAllOrgs',
  postProcess: (response) => response.orgs,
  request: (_, options) => detApi.Global.Orgs.listAllOrgsInDeployment(options),
});

export const fetchOrgQuotas = generateDetApi<
  { orgId: string },
  GlobalApi.ModelOrgQuotasResponse,
  GlobalApi.ModelOrgQuotas | undefined
>({
  name: 'fetchOrgQuotas',
  postProcess: (response) => response.quotas,
  request: ({ orgId }, options) => detApi.Global.OrgQuotas.getOrgQuotas(orgId, options),
});

export const updateOrgQuotas = generateDetApi<
  { orgId: string; orgQuotas: GlobalApi.ModelUpdateOrgQuotasRequest },
  GlobalApi.ModelOrgQuotasResponse,
  GlobalApi.ModelOrgQuotasResponse
>({
  name: 'updateOrgQuotas',
  postProcess: (response) => response,
  request: ({ orgId, orgQuotas }, options) =>
    detApi.Global.OrgQuotas.updateOrgQuotas(orgId, orgQuotas, options),
});

export const createOrg = generateDetApi<
  GlobalApi.ModelCreateOrgRequest,
  GlobalApi.ModelCreateOrgResponse,
  GlobalApi.ModelCreateOrgResponse
>({
  name: 'createOrg',
  postProcess: (response) => response,
  request: ({ backendProvider, name, nextOrgGuid }, options) =>
    detApi.Global.Orgs.createOrg({ backendProvider, name, nextOrgGuid }, options),
});

export const updateOrgName = generateDetApi<
  { name: string; orgId: string },
  GlobalApi.ModelRESTResponse,
  GlobalApi.ModelRESTResponse
>({
  name: 'updateOrg',
  postProcess: (response) => response,
  request: ({ name, orgId }, options) => detApi.Global.Orgs.updateOrg(orgId, { name }, options),
});

export const getOrgMembers = generateDetApi<
  { orgId: string },
  GlobalApi.ModelGetOrgUsersResponse,
  GlobalApi.ModelOrgUser[]
>({
  name: 'getOrgMembers',
  postProcess: (response) => response.users,
  request: ({ orgId }, options) => detApi.Global.Auth.getOrgUsers(orgId, options),
});

/**
 * Upsert an org member.
 * @param userRoles: the full set of user roles to upsert
 */
export const upsertOrgUser = generateDetApi<
  { orgId: string; userId: string; userRoles: GlobalApi.ModelUpdateOrgUserRequest },
  void,
  void
>({
  name: 'updateOrgUser',
  postProcess: identity,
  request: async ({ orgId, userId, userRoles }, options) => {
    await detApi.Global.Auth.updateOrgUser(orgId, userId, userRoles, options);
  },
});

export const deleteOrgUser = generateDetApi<{ orgId: string; userId: string }, void, void>({
  name: 'deleteOrgUser',
  postProcess: identity,
  request: async ({ orgId, userId }, options) => {
    await detApi.Global.Auth.deleteOrgUser(orgId, userId, options);
  },
});

export const getOrgBackendProviders = generateDetApi<
  { orgId: string },
  GlobalApi.ModelGetOrgBackendProvidersResponse,
  GlobalApi.ModelPartialBackendProviderEntry[]
>({
  name: 'getOrgBackendProviders',
  postProcess: (response) => response.backendProviders,
  request: ({ orgId }, options) => detApi.Global.Auth.getOrgBackendProviders(orgId, options),
});

export const getOrgBackendProviderData = generateDetApi<
  { orgId: string },
  GlobalApi.ModelGetOrgBackendProviderDataResponse,
  GlobalApi.ModelFullBackendProviderEntry[]
>({
  name: 'getOrgBackendProviderData',
  postProcess: (response) => response.backendProviders,
  request: ({ orgId }, options) => detApi.Global.Auth.getOrgBackendProviderData(orgId, options),
});

export const createBackendProvider = generateDetApi<
  { backendProvider: GlobalApi.ModelBackendProvider; orgId: string },
  GlobalApi.ModelCreateBackendProviderResponse,
  GlobalApi.ModelCreateBackendProviderResponse
>({
  name: 'createBackendProvider',
  postProcess: (response) => response,
  request: ({ backendProvider, orgId }, options) =>
    detApi.Global.Auth.createBackendProvider(orgId, backendProvider, options),
});

export const updateBackendProvider = generateDetApi<
  { backendProvider: GlobalApi.ModelBackendProvider; backendProviderId: string; orgId: string },
  GlobalApi.ModelUpdateBackendProviderResponse,
  GlobalApi.ModelUpdateBackendProviderResponse
>({
  name: 'updateBackendProvider',
  postProcess: (response) => response,
  request: ({ backendProvider, backendProviderId, orgId }, options) =>
    detApi.Global.Auth.updateBackendProvider(orgId, backendProviderId, backendProvider, options),
});

/**
 * Get all users invited to an org
 * @param orgId: organization Id
 */
export const getOrgUserInvites = generateDetApi<
  { orgId: string },
  GlobalApi.ModelGetInvitedUsersResponse,
  GlobalApi.ModelInvitedUser[]
>({
  name: 'getOrgInvites',
  postProcess: (response) => response.invitedUsers,
  request: async ({ orgId }, options) => await detApi.Global.Auth.getOrgInvites(orgId, options),
});

/**
 * Invite a user to an org
 * @param userRoles: the full set of user roles to upsert
 */
export const inviteOrgUser = generateDetApi<
  { orgId: string; userInfo: GlobalApi.ModelInviteOrgUserRequest },
  void,
  void
>({
  name: 'inviteOrgUser',
  postProcess: identity,
  request: async ({ orgId, userInfo }, options) => {
    await detApi.Global.Auth.inviteOrgUser(orgId, userInfo, options);
  },
});

/**
 * @param inviteId: Id for user invite
 * @param orgId: organization Id
 */
export const cancelOrgInvite = generateDetApi<{ inviteId: string; orgId: string }, void, void>({
  name: 'cancelOrgInvite',
  postProcess: identity,
  request: async ({ orgId, inviteId }, options) => {
    await detApi.Global.Auth.cancelOrgInvite(orgId, inviteId, options);
  },
});

/* Clusters */
export const fetchClusters = generateDetApi<
  { orgId: string },
  GlobalApi.ModelListClustersResponse,
  GlobalApi.ModelClusterInfo[]
>({
  name: 'fetchClusters',
  postProcess: (response) => response.clusters,
  request: ({ orgId }, options) => detApi.Global.Cluster.listClusters(orgId, options),
});

export const fetchRegionalClusters = generateDetApi<
  ClusterScope,
  GlobalApi.ModelListClustersResponse,
  GlobalApi.ModelClusterInfo[]
>({
  name: 'fetchRegionClusters',
  postProcess: (response) => response.clusters,
  request: (
    { orgId, regionId },
    options, // TODO: this is gonna be a repeated pattern.
  ) => detApi.Regional[regionId].Cluster.listClusters(orgId, options),
});

export const updateClusterUser = generateDetApi<
  {
    clusterId: string;
    orgId: string;
    update: GlobalApi.ModelUpdateClusterUserRequest;
    userId: string;
  },
  void,
  void
>({
  name: 'updateClusterUser',
  postProcess: identity,
  request: async ({ clusterId, orgId, userId, update }, options) => {
    await detApi.Global.Auth.updateClusterUser(orgId, clusterId, userId, update, options);
  },
});

/** upgrade cluster version */
export const upgradeCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
    version: string;
  },
  void,
  void
>({
  name: 'upgradeCluster',
  postProcess: identity,
  request: async ({ clusterId, orgId, version, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.upgradeCluster(
      orgId,
      clusterId,
      { detVersion: version },
      options,
    );
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterUpgraded,
    );
  },
});

export const deleteCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
  },
  void,
  void
>({
  name: 'deleteCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.deleteCluster(orgId, clusterId, options);
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterDeleted,
    );
  },
});

export const pauseCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
  },
  void,
  void
>({
  name: 'pauseCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.pauseCluster(orgId, clusterId, options);
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterPaused,
    );
  },
});

export const resumeCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
  },
  void,
  void
>({
  name: 'resumeCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.resumeCluster(orgId, clusterId, options);
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterResumed,
    );
  },
});

export const retryClusterAction = generateDetApi<
  {
    clusterId: GlobalApi.ModelClusterInfo['id'];
    orgId: string;
    regionId: string;
  },
  GlobalApi.ModelRESTResponse,
  GlobalApi.ModelRESTResponse
>({
  name: 'retryClusterAction',
  postProcess: (response) => response,
  request: ({ clusterId, orgId, regionId }) => {
    return detApi.Regional[regionId].Cluster.retryClusterAction(orgId, clusterId, regionId);
  },
});

/**
 * Get the effective list of a cluster's users.
 */
export const getClusterUsers = generateDetApi<
  {
    clusterId: string;
    orgId: string;
  },
  GlobalApi.ModelGetClusterUsersResponse,
  GlobalApi.ModelClusterUser[]
>({
  name: 'getClusterUsers',
  postProcess: (response) => response.users,
  request: ({ clusterId, orgId }, options) => {
    return detApi.Global.Auth.getClusterUsers(orgId, clusterId, options);
  },
});

/**
 * Get the effective list of a user's clusters.
 */
export const getUserClusters = generateDetApi<
  {
    orgId: string;
    userId: string;
  },
  GlobalApi.ModelGetUserClustersResponse,
  GlobalApi.ModelClusterAccessInfo[]
>({
  name: 'getUserClusters',
  postProcess: (response) => response.clusters ?? [],
  request: ({ userId, orgId }, options) => {
    return detApi.Global.Auth.getUserClusters(orgId, userId, options);
  },
});

/**
 * Get cluster access overrides for an organization.
 */
export const getClusterOverrides = generateDetApi<
  {
    orgId: string;
  },
  GlobalApi.ModelGetClusterOverridesResponse,
  Record<ClusterID, GlobalApi.ModelClusterUser[]>
>({
  name: 'getClusterOverrides',
  postProcess: (response) => response.clusters,
  request: ({ orgId }, options) => {
    return detApi.Global.Auth.getClusterOverrides(orgId, options);
  },
});

/**
 * add an override access override for user to a cluster.
 */
export const updateClusterLevelRole = generateDetApi<
  {
    clusterId: string;
    orgId: string;
    role: ClusterRole;
    userId: string;
  },
  void,
  void
>({
  name: 'addClusterLevelRole',
  postProcess: identity,
  request: async ({ orgId, clusterId, userId, role }, options) => {
    await detApi.Global.Auth.updateClusterUser(orgId, clusterId, userId, { role }, options);
  },
});

export const createCluster = generateDetApi<
  ClusterScope & {
    cluster: RegionalApi.ModelCreateClusterRequest;
  },
  RegionalApi.ModelCreateClusterResponse,
  string
>({
  name: 'createCluster',
  postProcess: (response) => response.id,
  request: async ({ cluster, orgId, regionId }, options) => {
    const c = await detApi.Regional[regionId].Cluster.createCluster(orgId, cluster, options);
    notifStorage.addClusterSub(c, SubscriptionType.ClusterCreated);
    return c;
  },
});

export const getSupportMatrix = generateDetApi<
  void,
  GlobalApi.ModelGetSupportMatrixResponse,
  GlobalApi.ModelGetSupportMatrixResponse
>({
  name: 'getSupportMatrix',
  postProcess: (response) => response,
  request: (options) => detApi.Global.SupportMatrix.getSupportMatrix(options),
});

export const getCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
  },
  RegionalApi.ModelGetClusterResponse,
  ClusterDetails
>({
  name: 'getCluster',
  postProcess: (response) => {
    const poolConfigs = response.cluster.masterConfig.resource_pools?.map(
      (pool) => decodePoolConfig(pool) ?? [],
    );
    return {
      ...response.cluster,
      masterConfig: {
        ...response.cluster.masterConfig,
        resource_pools: poolConfigs,
      },
    };
  },
  request: ({ clusterId, orgId, regionId }, options) =>
    detApi.Regional[regionId].Cluster.getCluster(orgId, clusterId, options),
});

/**
 * terminate and reprovision the Determined master instance with modified parameters
 */
export const reprovisionCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
    instanceType: RegionalApi.ModelReprovisionClusterRequest;
  },
  void,
  void
>({
  name: 'reprovisionCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, instanceType, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.reprovisionCluster(
      orgId,
      clusterId,
      instanceType,
      options,
    );
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterReprovisioned,
    );
  },
});

/**
 * restart the Determined master process with a new configuration
 */
export const reconfigureCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
    config: RegionalApi.ModelReconfigureClusterRequest;
  },
  void,
  void
>({
  name: 'reconfigureCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, config, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.reconfigureCluster(orgId, clusterId, config, options);
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterReconfigured,
    );
  },
});

export const editCluster = generateDetApi<
  ClusterScope & {
    cluster: RegionalApi.ModelEditClusterRequest;
    clusterId: string;
  },
  void,
  void
>({
  name: 'editCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, cluster, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.editCluster(orgId, clusterId, cluster, options);
  },
});

/**
 * Get the cluster's action history
 */
export const getClusterActions = generateDetApi<
  ClusterScope & {
    cluster: GlobalApi.ModelClusterInfo;
  },
  RegionalApi.ModelGetClusterActionsResponse,
  RegionalApi.ModelActionInfo[]
>({
  name: 'getClusterActions',
  postProcess: (response) => response.actions,
  request: ({ cluster, orgId, regionId }, options) => {
    return detApi.Regional[regionId].Cluster.getClusterActions(orgId, cluster.id, options);
  },
});

/**
 * Check if the global management plane is reachable
 * @param timeout timeout in seconds
 * This api handler is NOT generated like the other api handlers.
 */
export const globalIsAlive = async (timeout: number): Promise<void> => {
  const timeoutCanceler = new AbortController();
  setTimeout(() => timeoutCanceler.abort(), timeout);
  try {
    await fetch(globalServerAddress('/health-check'), {
      method: 'GET',
      signal: timeoutCanceler.signal,
    });
  } catch (e: unknown) {
    throw new DetError(e, { publicMessage: 'Global server is not reachable', silent: true });
  }
};

export const streamingLogsRequest = (
  clusterId: string,
  orgId: string,
  regionId: string,
): RegionalApi.FetchArgs => {
  const request = detApi.Regional[regionId].LogStream.getFailureLogs(orgId, clusterId);
  request.options.credentials = 'include';
  return request;
};

/**
 * Get all orgs the user has been invited to
 * @param orgId: organization Id
 */

export const fetchUsersInvites = generateDetApi<
  string,
  GlobalApi.ModelListUserInvitesResponse,
  GlobalApi.ModelListUserInvitesResponse
>({
  name: 'fetchUsersInvites',
  postProcess: (response) => response,
  request: (userId, options) => detApi.Global.Invites.listUserInvites(userId, options),
});

/**
 * Claim an invite
 * @param orgId: organization Id
 */

export const claimUserInvite = generateDetApi<
  { userId: string; inviteId: string },
  GlobalApi.ModelRESTResponse,
  GlobalApi.ModelRESTResponse
>({
  name: 'fetchUsersInvites',
  postProcess: (response) => response,
  request: ({ userId, inviteId }, options) =>
    detApi.Global.Invites.claimInvite(userId, inviteId, options),
});
