import { DeploymentUnitOutlined } from '@ant-design/icons';
import Button from 'hew/Button';
import { useModal } from 'hew/Modal';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { telemetryInstance } from 'analytics';
import Message, { MessageType } from 'components/Message';
import Page from 'components/Page';
import Spinner from 'components/Spinner';
import { useStore } from 'contexts/Store';
import { useUser } from 'contexts/User';
import { useFetchClusters } from 'experimental/notifications/hooks';
import useBackendProvider from 'hooks/useBackendProvider';
import useConfig from 'hooks/useConfig';
import usePolling from 'hooks/usePolling';
import { _NewGkeMldeClusterModal } from 'pages/Clusters/GkeCluster/NewGkeMldeClusterModal';
import { _NewAwsMldeClusterModal } from 'pages/Clusters/NewAwsMldeClusterModal';
import { _NewAwsMldmClusterModal } from 'pages/Clusters/NewAwsMldmClusterModal';
import * as GlobalApi from 'services/global-bindings';
import { Backend } from 'types';
import handleError from 'utils/error';
import { getOrganizationIdentifier, isOrgAdmin } from 'utils/saas';

import ClusterCard from './ClusterCard';
import css from './ClusterCards.module.scss';
import { _NewByokMldeClusterModal } from './NewByokMldeClusterModal';

const defaultModalOpenState = {
  awsMlde: false,
  awsMldm: false,
  byokMlde: false,
  gcpMlde: false,
};

const Clusters: React.FC = () => {
  const [clusters, setClusters] = useState<GlobalApi.ModelClusterInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [canceler] = useState(() => new AbortController());
  const {
    orgState: { selectedOrg },
  } = useStore();
  const { roles } = useUser();
  // This ref ensures that we only send the tracking event once in useEffect
  const trackingSent = useRef(false);
  const fetchClusters = useFetchClusters(canceler, { rethrowError: true });
  const config = useConfig(canceler);

  const loadClusters = useCallback(async () => {
    if (!selectedOrg) return;
    const newClusters = await fetchClusters();
    setLoading(false);
    if (!newClusters) return;
    setClusters(newClusters);
  }, [fetchClusters, selectedOrg]);

  useEffect(() => {
    if (!trackingSent.current) {
      telemetryInstance.page();
      trackingSent.current = true;
    }
  }, []);

  usePolling(loadClusters, {
    errorHandler: (e) =>
      handleError(e, {
        publicSubject: 'Failed to fetch clusters',
      }),
  });

  useEffect(() => {
    setClusters([]);
  }, [loadClusters, selectedOrg]);

  useEffect(() => {
    setClusters([]);
  }, [loadClusters, selectedOrg]);

  // signal cancellation on unmount
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const emptyMessage = useCallback(() => {
    if (loading) return <Spinner tip="Fetching list of clusters" />;
    return (
      <Message
        message={
          selectedOrg && isOrgAdmin(roles, selectedOrg.id)
            ? 'Create a cluster configured to your resource and training needs'
            : selectedOrg && !isOrgAdmin(roles, selectedOrg.id)
            ? 'Ask your organization admin to create a cluster configured to your resource and training needs'
            : ''
        }
        title="No clusters"
        type={MessageType.Empty}
      />
    );
  }, [loading, selectedOrg, roles]);

  const backendProviderName = useBackendProvider(canceler);

  const NewAwsMldeClusterModal = useModal(_NewAwsMldeClusterModal);
  const NewGkeMldeClusterModal = useModal(_NewGkeMldeClusterModal);
  const NewAwsMldmClusterModal = useModal(_NewAwsMldmClusterModal);
  const NewByokMldeClusterModal = useModal(_NewByokMldeClusterModal);

  const [isModalOpen, setIsModalOpen] = useState(defaultModalOpenState);

  const modalMldeOpen = useMemo(() => {
    return backendProviderName === Backend.EC2 || backendProviderName === Backend.EKS
      ? NewAwsMldeClusterModal.open
      : backendProviderName === Backend.GKE
      ? NewGkeMldeClusterModal.open
      : () => undefined;
  }, [backendProviderName, NewAwsMldeClusterModal.open, NewGkeMldeClusterModal.open]);

  if (!selectedOrg)
    return <Message title={`Waiting for an ${getOrganizationIdentifier()} selection..`} />;
  if (!config) return <Message title="Loading app config..." />;

  return (
    <Page
      options={
        <div className={css.newClusterButtonsContainer}>
          {config.mldeEnabled &&
            (backendProviderName === Backend.EC2 || backendProviderName === Backend.GKE) && (
              <Button
                block
                disabled={
                  !Object.values(Backend).includes(backendProviderName as Backend) ||
                  !isOrgAdmin(roles, selectedOrg.id)
                }
                icon={<DeploymentUnitOutlined />}
                onClick={() => {
                  if (backendProviderName === Backend.EC2) {
                    setIsModalOpen({
                      ...defaultModalOpenState,
                      awsMlde: true,
                    });
                  } else if (backendProviderName === Backend.GKE) {
                    setIsModalOpen({
                      ...defaultModalOpenState,
                      gcpMlde: true,
                    });
                  }
                  modalMldeOpen();
                }}>
                New MLDE Cluster
              </Button>
            )}
          {config.mldmEnabled && backendProviderName === Backend.EKS && (
            <Button
              block
              disabled={
                !Object.values(Backend).includes(backendProviderName as Backend) ||
                !isOrgAdmin(roles, selectedOrg.id)
              }
              icon={<DeploymentUnitOutlined />}
              onClick={() => {
                if (backendProviderName === Backend.EKS) {
                  setIsModalOpen({
                    ...defaultModalOpenState,
                    awsMldm: true,
                  });
                  NewAwsMldmClusterModal.open();
                }
              }}>
              New MLDM Cluster
            </Button>
          )}
          {/* This is very temporary and we would not have a separate button
              once the regional split takes place */}
          {config.byokEnabled && backendProviderName === Backend.BYOK8S && (
            <Button
              block
              disabled={
                !Object.values(Backend).includes(backendProviderName as Backend) ||
                !isOrgAdmin(roles, selectedOrg.id)
              }
              icon={<DeploymentUnitOutlined />}
              onClick={() => {
                if (backendProviderName === Backend.BYOK8S) {
                  setIsModalOpen({
                    ...defaultModalOpenState,
                    byokMlde: true,
                  });
                  NewByokMldeClusterModal.open();
                }
              }}>
              New BYOK Cluster
            </Button>
          )}
        </div>
      }
      title="Clusters">
      {clusters.length > 0 && (
        <div className={css.cardsWrapper}>
          {clusters.map((cluster, i) => (
            <div id={cluster.id} key={i}>
              <ClusterCard
                cluster={cluster}
                isBYOK={config.byokEnabled && backendProviderName === Backend.BYOK8S}
                loadClusters={loadClusters}
                orgId={selectedOrg.id}
              />
            </div>
          ))}
        </div>
      )}
      {clusters.length === 0 && emptyMessage()}
      <NewAwsMldeClusterModal.Component
        isModalOpen={isModalOpen.awsMlde}
        onClose={() => setIsModalOpen(defaultModalOpenState)}
        onFinish={loadClusters}
      />
      <NewAwsMldmClusterModal.Component
        isModalOpen={isModalOpen.awsMldm}
        onClose={() => setIsModalOpen(defaultModalOpenState)}
        onFinish={loadClusters}
      />
      <NewGkeMldeClusterModal.Component
        isModalOpen={isModalOpen.gcpMlde}
        onClose={() => setIsModalOpen(defaultModalOpenState)}
        onFinish={loadClusters}
      />
      <NewByokMldeClusterModal.Component
        isModalOpen={isModalOpen.byokMlde}
        onClose={() => setIsModalOpen(defaultModalOpenState)}
        onFinish={loadClusters}
      />
    </Page>
  );
};

export default Clusters;
